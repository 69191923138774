import React from "react"
import Layout from "./src/components/layouts/main"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

// export const shouldUpdateScroll = ({
//   prevRouterProps: { location: prevLocation },
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   const currentPosition = getSavedScrollPosition(location)
//   const queriedPosition = getSavedScrollPosition({ pathname: `/careers` })
//   if (prevLocation == "/careers" && /\/careers\//.test(location)) {
//     return false
//   } else if (location == "/careers" && /\/careers\//.test(prevLocation)) {
//     return false
//   } else {
//     return true
//   }
//   // window.scrollTo(...(currentPosition || [0, 0]))
// }
