import React from "react"
import "../../theme/style.scss"

import { Global, css } from "@emotion/core"
import Footer from "./Footer"
const Layout = ({ children }) => (
  <React.Fragment>
    <Global
      styles={css`
        .bold-headline {
          font-family: "futura-pt", sans-serif;
          font-weight: 600;
          font-style: normal;
        }
        .section-y-padding {
          padding-top: 4rem;
          padding-bottom: 4rem;

          @media (min-width: 768px) {
            padding-top: 100px;
            padding-bottom: 100px;
          }
          @media (min-width: 992px) {
            padding-top: 120px;
            padding-bottom: 120px;
          }
        }
        .hover-shadow {
          &:hover {
            box-shadow: 0 7px 7px -7px rgba(0, 0, 0, 0.15);
          }
        }
        .circle-background {
          align-self: center;
          position: relative;

          &::after {
            position: absolute;
            z-index: -1;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            background: red;
            border-radius: 50%;
          }
        }
      `}
    />
    {children}
    <Footer />
  </React.Fragment>
)

export default Layout
