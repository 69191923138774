// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-page-js": () => import("/opt/build/repo/src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-role-page-js": () => import("/opt/build/repo/src/templates/role-page.js" /* webpackChunkName: "component---src-templates-role-page-js" */),
  "component---src-templates-generic-page-js": () => import("/opt/build/repo/src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("/opt/build/repo/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investors-js": () => import("/opt/build/repo/src/pages/investors.js" /* webpackChunkName: "component---src-pages-investors-js" */),
  "component---src-pages-products-js": () => import("/opt/build/repo/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-thank-you-js": () => import("/opt/build/repo/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

